import React, { useEffect, useState } from "react";
import { useFetching } from "../../../hooks/useFetching";
import RequestService from "../../../api/RequestService";
import useConfirm from "../../../context/ConfirmDialog";
import Message from "../Message/Message";
import { Textarea, Button, Icon } from "react-materialize";
import { parseForm } from "../../../utils/service";

const ChatWidget = ({ messagesCount = 0 }) => {
  const confirm = useConfirm();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [sendBtnState, setSendBtnState] = useState(false);
  let recipient = "support";

  const toggleChat = () => {
    if (!isOpen) {
      RequestService.sendLog("openChat", recipient);
      fetchMessages(messagesPage);
    }
    setIsOpen(!isOpen);
  };

  const closeChat = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    scrollChatWidget();
  }, [messages, isOpen]);

  const getSupportMessages = async (page = 1) => {
    const response = (await RequestService.getChatMessages(recipient, page))
      .data;
    // console.log(response);
    if (!response.response_code) {
      // await confirm({
      //   description: response.message,
      //   isAlert: true,
      // });
      // alert(response.message);
      return;
    }
    response.result.elements.sort((a, b) => b.num - a.num);
    setMessages(response.result.elements);
  };
  const [fetchMessages, isMessagesLoading, messagesError] =
    useFetching(getSupportMessages);

  useEffect(() => {
    // fetchMessages(messagesPage);
  }, [messagesPage]);

  const scrollChatWidget = () => {
    let chatWidgetPlace = document.getElementById("chat-widget-place");
    if (chatWidgetPlace) {
      chatWidgetPlace.scrollTop = chatWidgetPlace.scrollHeight;
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setSendBtnState(true);

    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);

    const response = (
      await RequestService.sendForm(sendFormData, "/messages/send")
    ).data;
    // console.log(response);

    setSendBtnState(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    setInputValue("");
    RequestService.sendLog("openChat", recipient);
    fetchMessages();
  };

  return (
    <div className="chat-widget">
      {!isOpen && (
        <div className="chat-btn" onClick={toggleChat}>
          {messagesCount ? (
            <i className="error-color">
              <span
                className="accent-counter"
                style={{
                  fontStyle: "normal",
                  backgroundColor: "var(--error-color)",
                  position: "absolute",
                  zIndex: 100000,
                  top: "-10px",
                  left: "40px",
                }}
              >
                {messagesCount > 9 ? "9+" : messagesCount}
              </span>
            </i>
          ) : null}
          <i className="material-icons chat-btn-icon">chat</i>
        </div>
      )}
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <span className="m500">Чат поддержки</span>
            <button className="chat-close-button" onClick={closeChat}>
              ✖
            </button>
          </div>
          <div className="chat-header-info">
            <span>
              🚀 А еще нам можно писать прямо в{" "}
              <a
                href="https://t.me/NottSupportBot"
                className="iq-link"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>{" "}
              даже без авторизации
            </span>
          </div>
          <div id="chat-widget-place" className="chat-messages row nm">
            {/* {messages.map((message, index) => (
              <div key={index} className={`chat-message ${message.sender}`}>
                {message.text}
              </div>
            ))} */}
            {!messages || messages.length === 0 ? (
              <div className="col s12 center-align">Сообщений нет</div>
            ) : (
              <div className="col s12">
                {messages.map((message) => (
                  <Message
                    isOwner={message.is_owner}
                    name={
                      !message.is_owner
                        ? message.to_user_name
                        : message.from_user_name
                    }
                    text={message.text}
                    datetime={message.timestamp}
                    userAlias={message.from_user_alias}
                    isModerator={message.is_moderator}
                    isOrganizer={message.is_organizer}
                    avatarUrl={message.avatar_img_url}
                    fullWidthMessage={true}
                    key={message.num + "-" + message.timestamp}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="chat-input">
            {/* <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Введите сообщение..."
            />
            <button onClick={handleSendMessage}>Отправить</button> */}

            <form onSubmit={handleSendMessage}>
              <div className="row nm">
                <Textarea
                  id="message-text"
                  name="message-text"
                  placeholder={"Сообщение..."}
                  s={10}
                  style={{ marginBottom: "0px" }}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  value={inputValue}
                />
                <input type="hidden" name="message-to-user" value={recipient} />
                <div className="col s2 center-align mt20">
                  <Button
                    flat
                    disabled={sendBtnState}
                    className="iq-btn"
                    type="submit"
                    icon={<Icon>send</Icon>}
                  ></Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
